import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { UpdateAccountPayload } from '../update-account.service';

export const updateAccountAction = createAction(
  ActionTypes.UPDATE_ACCOUNT,
  props<{ payload: UpdateAccountPayload }>()
);

export const updateAccountSuccessAction = createAction(
  ActionTypes.UPDATE_ACCOUNT_SUCCESS,
  props<{ data: any }>()
);

export const updateAccountFailureAction = createAction(
  ActionTypes.UPDATE_ACCOUNT_FAILURE,
  props<{ error: any }>()
);
